<template>
  <div class="px-2 mt-1">
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="(item, index) in tabs"
        :key="index"
        :to="{ name: item.route }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
      >
        {{ item.title }}
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <router-view />
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          route: "shared-of-program",
          title: "Of The Program",
        },
        {
          route: "shared-other-program",
          title: "From Other Programs",
        },
      ],
    };
  },
};
</script>